import React from "react"

import Layout from "components/Layout/de"
import Image from "components/Image/index"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "components/Layout/Link"
import imgHeader from "./header.jpg"

import imgToolbox from "./toolbox/productToolBox.png"
import imgWood from "./woodscanner/product.jpg"
import imgMetal from "./metalscanner/product.jpg"
import imgWeld from "./weldscanner/product.jpg"
import imgPaint from "./paintscanner/product.jpg"
import imgAssemblyControl from "./assemblycontrol/product.jpg"
import imgSurface from "./surfacescanner/product.jpg"
import imgLogistics from "./logisticscontrol/productLogisticsControl.jpg"

import styles from "styles/global.module.scss"

export default function(props) {
  const header = {
    x: 39,
    y: 49,
    text: "Automatisierung der Qualitätskontrolle mit Deep Learning",
    description:
      "Hohe Erkennungsraten und schnelle Durchlaufzeiten in der Oberflächenprüfung und in der Montagelinie",
    image: imgHeader,
  }
  return (
    <Layout header={header} location={props.location}>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image filename={imgSurface} alt="Moonvision SurfaceScanner" />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision SurfaceScanner</small>
                <br />
                Industrielle Qualitätsprüfung von Oberflächen
              </h2>
              <p>
                Beflügeln Sie ihr Qualitätsmanagement mit dem MoonVision
                SurfaceScanner. Fehler auf unterschiedlichen Oberflächen werden
                vollautomatisiert visuell erkannt und Unterschieden. Das
                Ergebnis sind ideale Produkte und signifikant verkürzte
                Qualitätsprüfungen.
              </p>
              <Link to="/products/surfacescanner" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgLogistics}
                alt="Moonvision LogisticsControl"
              />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">
                  MoonVision LogisticsControl
                </small>
                <br />
                Digitale Leergut-Paletten-Erkennung in Logistik-Prozessen
              </h2>
              <p>
                Automatisieren Sie Ihre Logistik-Prozesse durch das effiziente
                Leercontainer-Tracking-System von MoonVision – durch visuelle
                Objekterkennung an Fahrzeugen (Staplern) zur Lückenlosen
                Bilddokumentation vom Wareneingang bis hin zur Abfertigung.
                MoonVision verfügt über höchste Erkennungsgenauigkeit dank
                umfassende Erfahrung.
              </p>
              <Link to="/products/logisticscontrol" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgWood}
                alt="WoodScanner mit PC, Kamera und Holz"
              />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision WoodScanner</small>
                <br />
                Automatisierte Oberflächenprüfung von Holz
              </h2>
              <p>
                Bestimmen Sie präzise und automatisiert die Qualität von
                Holzstämmen noch bevor Sie diese verarbeiten. Die MoonVision
                Software nutzt visuelle Daten von Kameras um zu prüfen – ob ihre
                Qualitätsmerkmale erfüllt werden und welche Fläche für
                nachgelagerte Verwendungszwecke genutzt werden kann.
              </p>
              <Link to="/products/woodscanner" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgMetal}
                alt="MetalScanner mit PC, Kamera und Metallstücken"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision MetalScanner</small>
                <br />
                Qualitätskontrolle metallischer Oberflächen
              </h2>
              <p>
                Automatisieren Sie die Unterscheidung von Oberflächendefekten
                auf Metall auf unter eine Sekunde! Die MoonVision Software
                unterscheidet automatisch zwischen verschiedenen Oberflächen und
                Defektarten und leitet diese Information weiter wo sie diese am
                dringensten benötigen.
              </p>
              <Link to="/products/metalscanner" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgPaint}
                alt="PaintScanner mit PC, Kamera und Lackstücken"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision PaintScanner</small>
                <br />
                Fehlererkennung auf lackierten Oberflächen
              </h2>
              <p>
                Untersuchen Sie automatisiert mit Hilfe der MoonVision
                PaintScanner lackierte Oberflächen nach Defekten und
                Qualitätseigenschaften. Durch die Nutzung von visuelle Daten,
                erzeugt durch Kameras, wird Ihnen frühzeitig mitgeteilt – ob
                ihre Qualitätsmerkmale erfüllt werden und/oder ob es ein
                Handlungsbedarf besteht.
              </p>
              <Link to="/products/paintscanner" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgWeld}
                alt="WeldScanner mit PC, Kamera und geschweißter Halterung"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision WeldScanner</small>
                <br />
                Schweißnaht-Prüfung & Vermessung
              </h2>
              <p>
                Reduzieren Sie die Zeit für die Vermessung von Schweißnähten (zB
                nach DIN EN 17637) auf unter eine Sekunde! Die MoonVision
                Software unterscheidet automatisch und präzise die Schweißnaht
                vom Werkstück und misst visuell, ob das Ergebnis des
                Fügeverfahrens ihren Qualitätsanforderungen entspricht. Dabei
                werden unterschiedliche Nahttypen erkannt und berücksichtigt.
              </p>
              <Link to="/products/weldscanner" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgAssemblyControl}
                alt="AssemblyControl mit PC, Kamera und elektronik teile"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">
                  Digitale Montaglinien-Prüfung
                </small>
                <br />
                Prüfung in Montageprozessen – richtige Positionierung von
                Bauteilen
              </h2>
              <p>
                Mit MoonVision AssemblyControl steht Ihnen ein Werkzeugset für
                die Fertigung zur Verfügung, mit dem individuell gefertigte
                Bauteile nach deren Zusammensetzung, Positionierung und
                Ausrichtung hin automatisiert überprüft werden. Dabei erfolgt
                auf Wunsch auch ein Soll/Ist Vergleich mit ihren bestehenden
                Datenquellen. Kunden profitieren von einer stabilen und
                zuverlässigen Qualitätsprüfung in ihrem Produktionsprozess und
                stark verkürzten Durchlaufzeiten.
              </p>
              <Link to="/products/assemblycontrol" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image filename={imgToolbox} alt="Moonvision Toolbox mit PC" />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision Toolbox</small>
                <br />
                Das KI Toolset für Domänen Experten
              </h2>
              <p>Das effizienteste KI Tool am Markt.</p>
              <Link to="/products/toolbox" location={props.location}>
                <Button className="btn btn-primary">Mehr Informationen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
